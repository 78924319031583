const currencies = {
	usd: { title: 'USD', symbol: '$' },
	eur: { title: 'EUR', symbol: '€' },
	gbp: { title: 'GBP', symbol: '£' },
	zar: { title: 'ZAR', symbol: 'R' },
}

const plans = {
	single: {
		title: 'Single',
		price: { usd: 15, gbp: 15, eur: 15, zar: 100 },
		subTitle: `It's just you.\n50 tasks per month.`,
		features: ['2 Year History', 'Guest Access'],
	},
	pro: {
		title: 'Pro',
		price: { usd: 100, gbp: 100, eur: 100, zar: 750 },
		subTitle: 'Unlimited users.\n500 tasks per month.',
		features: ['2 Year History', 'Guest Access'],
	},
	medium: {
		title: 'Medium',
		price: { usd: 200, gbp: 200, eur: 200, zar: 1500 },
		subTitle: 'Unlimited users.\n1,500 tasks per month.',
		features: ['2 Year History', 'Guest Access'],
	},
	enterprise: {
		title: 'Enterprise',
		price: { usd: 500, gbp: 500, eur: 500, zar: 2500 },
		subTitle: 'Unlimited users.\n3,000+ tasks per month.',
		hasPlus: true,
		features: [
			'5 Year History',
			'Guest access',
			'API access',
			'Enterprise integration',
			'Priority support',
		],
	},
}

const plansDisplayOrder = ['single', 'pro', 'medium', 'enterprise']

module.exports = {
	currencies,
	plans,
	plansDisplayOrder,
}
