import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import cx from 'classnames'
import map from 'lodash/map'
import round from 'lodash/round'
import size from 'lodash/size'
import slice from 'lodash/slice'
import toLower from 'lodash/toLower'

import { makeStyles } from '@material-ui/core/styles'
import { Chip, Grid, Typography } from '@material-ui/core'

import { currencies, plans, plansDisplayOrder } from '../../config/pricing'
import { container } from '../sharedStyles'
import PricingPackage from '../components/PricingPackage'
import { useIpData } from '../utils/hooks'
import { usePageTransitionStyles } from '../utils/transitions'
import Layout from '../components/Layout'
import MarkdownRenderer from '../components/MarkdownRenderer'

const table = plansDisplayOrder.map(key => plans[key])

const faqs = [
	{
		question: 'What, no contracts?',
		answer:
			'Yep, that’s right. We have faith in our product. We love using it and we’re pretty darn sure you will too. If for some peculiar reason you don’t, well, why hold you over a barrel?',
	},
	{
		question: 'How much time do I have to trial Tyto?',
		answer:
			'Take as much time as you need. Your 30 days will not disappear unless you actually use them by creating, updating, deleting or completing tasks. This means that you won’t miss out on days that you might be on leave.\nYou can say it, because we already know… we’re awesome.',
	},
	{
		question: 'Is billing done monthly or annually?',
		answer:
			'Thought we’d have to trick you into staying with us for an entire year? Nope, not our style. There are no contracts, so billing takes place on a monthly basis.',
	},
	{
		question: 'Can I change plans at any time?',
		answer:
			'We appreciate that you may feel a little fickle at times, so feel free to upgrade or downgrade at will!\n\nUpgrades will be effective immediately and the difference will be billed in the next month. Downgrades will also be effective immediately and the difference will be credited against your account. (Credits only - no refunds.)',
	},
	{
		question: 'Can I deactivate users temporarily?',
		answer:
			'Yes, in the event that team members are planning on getting some bionic implants, or taking a boring trip to the coast, you can “pause” their account indefinitely. When they return with their superior arm-strength or a fabulous tan, you can reactivate them… if you want to.',
	},
	{
		question: 'Can I cancel at any time?',
		answer: `Sure. You can cancel… But let’s be honest. We’d be shocked. And then sad. We’ll probably cry a little. Possibly throw something ~~at the intern~~ at the wall. We might even try to win you back. Then cry some more. But eventually we’ll come to accept that you’re leaving. All that we ask is that you give us some time (7 days should do it*) to deal with the loss. After that, we’ll go our separate ways… But our door will always be open, just in case you have a change of heart.
		\nPlease bear mind that we do not pass refunds, so if you’re really thinking about doing the unthinkable, please send us your cancellation by no later than the 21st of your final month.`,
	},
	{
		question: 'Can I trial the product for free?',
		answer:
			'Somewhere in Vegas, an Elvis impersonator marries couples every day. Some of these couples hardly know one another. Mostly, they have no idea what they’re getting themselves into.\n\nWe are not Elvis impersonators. We don’t expect that sort of blind commitment.\n\nThat’s why we’d love for you to trial Tyto for 30 days – for free! Get to know her. See if you’re compatible. And if after 30 days you feel you can live without her, you get to walk away - zero commitment and guilt-free.',
	},
	{
		question:
			'What happens after the free trial period? Will you automatically start billing me?',
		answer:
			'No way! Once your trial is over, we’ll give you the option of signing up. Prior to that, there will be no exchange of billing information or any of that nonsense.',
	},
	{
		question: 'What payment options are available?',
		answer:
			'We love the idea of rolling around in piles of cash, or flying paper plane checks at a poker-faced cashier. Sadly, we don’t have the time. So we accept the Visa, Mastercard and Amex.',
	},
	{
		question: 'Will I have the rights to add and remove users myself?',
		answer:
			'In an age of political correctness, we say “down with equal rights!” At least, as far as account administration goes. As admin, you will have the ultimate power to add and remove users as you see fit. Of course, with great power comes great responsibility, but we think you can handle it.',
	},
	{
		question:
			'How will adding, removing and pausing users affect my billing?',
		answer:
			'We bill for the total number of active users in a month. If you add new users, we will prorate and bill in the following month. If you pause or delete users during the month, the difference will be credited against your account. (Again, these are credits only. No refunds. Ever.)',
	},
]

const useStyles = makeStyles(
	theme => ({
		root: {
			...container(theme),
			paddingBottom: theme.spacing(8),
			paddingTop: theme.spacing(8),
		},
		packageSection: {
			marginTop: theme.spacing(2),
		},
		faqSection: {
			marginTop: theme.spacing(8),
		},
	}),
	{ name: 'PricingPage' }
)

const PricingPage = props => {
	const classes = useStyles()
	const transitionClasses = usePageTransitionStyles(props)

	const [selectedCurrency, setSelectedCurrency] = useState('usd')
	const ipData = useIpData()
	useEffect(() => {
		if (ipData) {
			const currencyCode = ipData.currency
				? toLower(ipData.currency.code)
				: ''
			if (currencies[currencyCode]) {
				setSelectedCurrency(currencyCode)
			}
		}
	}, [ipData])

	const renderFaq = (item, index) => (
		<Grid item key={index}>
			<Typography gutterBottom variant={'h6'}>
				{item.question}
			</Typography>
			<Typography
				component={'div'}
				color={'textSecondary'}
				variant={'body1'}
			>
				<MarkdownRenderer>{item.answer}</MarkdownRenderer>
			</Typography>
		</Grid>
	)

	const faqHalfCount = round(size(faqs) / 2)

	return (
		<Layout>
			<Helmet>
				<title>Pricing</title>
			</Helmet>
			<div
				className={cx(
					classes.root,
					transitionClasses[props.transitionStatus]
				)}
			>
				<section>
					<Typography align={'center'} gutterBottom variant={'h3'}>
						Simple Pricing. No Contracts. No Hidden Fees.
					</Typography>
					<Typography align={'center'} gutterBottom variant={'h5'}>
						Our pricing is as honest as our promise.
					</Typography>
					<Grid container justify={'center'} spacing={1}>
						{map(currencies, (value, key) => (
							<Grid item key={key}>
								<Chip
									label={value.title}
									color={
										key === selectedCurrency
											? 'primary'
											: 'default'
									}
									onClick={() => setSelectedCurrency(key)}
								/>
							</Grid>
						))}
					</Grid>

					<Grid
						container
						justify={'center'}
						className={classes.packageSection}
						spacing={2}
					>
						{map(table, item => (
							<PricingPackage
								key={item.title}
								currencies={currencies}
								item={item}
								selectedCurrency={selectedCurrency}
							/>
						))}
					</Grid>
				</section>

				<Grid container className={classes.faqSection} spacing={5}>
					<Grid item xs={12} md={6}>
						<Grid container spacing={5}>
							{map(slice(faqs, 0, faqHalfCount), renderFaq)}
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container spacing={5}>
							{map(slice(faqs, faqHalfCount), renderFaq)}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Layout>
	)
}

export default PricingPage
