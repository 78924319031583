import React, { useContext } from 'react'
import map from 'lodash/fp/map'
import { Link as GatsbyLink } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Paper, Typography } from '@material-ui/core'

import { PricingPlanDispatch } from '../pricingPlanContext'

const useStyles = makeStyles(theme => ({
	root: {},
	currencySymbol: {
		marginTop: theme.spacing(2.5),
	},
	features: {
		marginTop: theme.spacing(4),
	},
	packageButton: {
		marginTop: theme.spacing(3),
	},
	packageContainer: {
		height: '100%',
		padding: theme.spacing(4),
		transition: theme.transitions.create(),

		'&:hover': {
			boxShadow: theme.shadows[4],
			transform: 'scale(1.01)',
		},
	},
	packageLink: {
		textDecoration: 'none',
	},
	price: {
		fontWeight: 800,
		fontSize: `calc(${theme.typography.h1.fontSize} * 0.9)`,
		position: 'relative',
	},
	plusIcon: {
		position: 'absolute',
		top: -theme.spacing(),
		right: -theme.spacing(),
	},
}))

const PricingPackage = ({ currencies, item, selectedCurrency }) => {
	const pricingPlanDispatch = useContext(PricingPlanDispatch)
	const classes = useStyles()

	const currency = currencies[selectedCurrency]

	const handleClick = plan => () =>
		pricingPlanDispatch({ type: 'setPlan', payload: plan })

	return (
		<Grid
			item
			className={classes.packageLink}
			component={GatsbyLink}
			key={item.title}
			to={'/signup'}
			onClick={handleClick(item)}
			xs={12}
			sm={6}
			md={6}
			lg={3}
		>
			<Paper className={classes.packageContainer}>
				<Grid container alignItems={'center'} direction={'column'}>
					<Typography align={'center'} gutterBottom variant={'h4'}>
						{item.title}
					</Typography>

					<Grid
						container
						justify={'center'}
						wrap={'nowrap'}
						className={classes.priceContainer}
					>
						<Typography
							className={classes.currencySymbol}
							variant={'h5'}
						>
							{currency.symbol}
						</Typography>
						<Typography className={classes.price} variant={'h1'}>
							{item.price[selectedCurrency]}
						</Typography>
						{!!item.hasPlus && (
							<Typography
								className={classes.currencySymbol}
								variant={'h5'}
							>
								+
							</Typography>
						)}
					</Grid>

					{map(text => (
						<Typography
							key={text}
							align={'center'}
							gutterBottom
							variant={'h5'}
						>
							{text}
						</Typography>
					))(item.subTitle.split('\n'))}

					<Button
						className={classes.packageButton}
						color={'primary'}
						size={'large'}
						variant={'contained'}
					>
						Pick Me!
					</Button>
					<Grid
						container
						className={classes.features}
						alignItems={'center'}
						direction={'column'}
						spacing={2}
					>
						{map(feature => (
							<Grid item key={feature}>
								<Typography
									color={'textSecondary'}
									variant={'body1'}
								>
									{feature}
								</Typography>
							</Grid>
						))(item.features)}
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	)
}

export default PricingPackage
