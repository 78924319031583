import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useReducer,
	useRef,
	useState,
} from 'react'

// React currently throws a warning when using useLayoutEffect on the server.
// To get around it, we can conditionally useEffect on the server (no-op) and
// useLayoutEffect in the browser. We need useLayoutEffect because we want
// `connect` to perform sync updates to a ref to save the latest props after
// a render is actually committed to the DOM.
const useBrowserLayoutEffect =
	typeof window !== 'undefined' ? useLayoutEffect : () => {}

const useSafeDispatch = dispatch => {
	const mounted = useRef(false)

	useBrowserLayoutEffect(() => {
		mounted.current = true
		return () => (mounted.current = false)
	}, [])

	return useCallback(
		(...args) => (mounted.current ? dispatch(...args) : undefined),
		[dispatch]
	)
}

const defaultInitialState = { data: null, error: null, status: 'idle' }

// Example usage:
// const {data, error, status, run} = useAsync()
// useEffect(() => {
//   run(api.signUp(formData))
// }, [formData, run])
const useAsync = (initialState, { throwErrors = false } = {}) => {
	const initialStateRef = useRef({ ...defaultInitialState, ...initialState })
	const [{ status, data, error }, setState] = useReducer(
		(state, action) => ({ ...state, ...action }),
		initialStateRef.current
	)

	const safeSetState = useSafeDispatch(setState)

	const setData = useCallback(
		data => safeSetState({ data, status: 'resolved' }),
		[safeSetState]
	)
	const setError = useCallback(
		error => safeSetState({ error, status: 'rejected' }),
		[safeSetState]
	)
	const reset = useCallback(() => safeSetState(initialStateRef.current), [
		safeSetState,
	])

	const run = useCallback(
		promise => {
			if (!promise || !promise.then) {
				throw new Error(
					`The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`
				)
			}
			safeSetState({ status: 'pending' })
			return promise.then(
				data => {
					setData(data)
					return data
				},
				error => {
					setError(error)

					// Only reject if explicitly asked to. Errors are already
					// handled and passed along.
					if (throwErrors) {
						return Promise.reject(error)
					} else {
						return null
					}
				}
			)
		},
		[safeSetState, setData, setError, throwErrors]
	)

	return {
		// Using the same names that react-query uses for convenience
		isIdle: status === 'idle',
		isLoading: status === 'pending',
		isError: status === 'rejected',
		isSuccess: status === 'resolved',

		setData,
		setError,
		error,
		status,
		data,
		run,
		reset,
	}
}

const useIpData = () => {
	const [ipData, setIpData] = useState()
	const safeSetIpData = useSafeDispatch(setIpData)
	useEffect(() => {
		fetch(
			`https://api.ipdata.co?api-key=${process.env.GATSBY_IPDATA_KEY}`
		)
			.then(data => data.json())
			.then(data => safeSetIpData(data))
	}, [safeSetIpData])
	return ipData
}

export { useAsync, useIpData }
